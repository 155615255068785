import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

import { PUBLIC_SENTRY_ENABLED } from '$env/static/public';
const useSentry = PUBLIC_SENTRY_ENABLED === 'true';

Sentry.init({
	dsn: 'https://17c18e32eb30eb6c7722cf50d8cdd880@o4507814219022336.ingest.us.sentry.io/4508147054346240',

	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [replayIntegration()],

	enabled: useSentry
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
